<template>
  <div class="content" style="height: 100%; width: 100%" id="LeftThree">
    <div class="list">
      <div class="list-title flex-b">
        <span>排名</span>
        <span>账号名称</span>
        <span>平台类型</span>
        <span>错误数</span>
      </div>
      <div class="list-box">
        <vue-seamless-scroll :data="list" :class-option="$main.classOption()">
          <div class="list-item flex-b-c" v-for="(item, index) in [...list,...list,...list,...list,...list,...list,...list]" :key="index">
            <span>
              <div class="index" :class="getIndexColor((index+1)%10)">{{(index+1)%10?(index+1)%10:10}}</div>
            </span>
            <!-- <el-tooltip popper-class="atooltip" effect="dark" :content="item.accountName" :disabled="item.accountName.length<7" placement="right-start"> -->
              <span class="TextHieedn" :title="item.accountName">{{ item.accountName ||"无" }}</span>
            <!-- </el-tooltip> -->
            <span class="TextHieedn">{{ item.platformType ||"无"}}</span>
            <span class="TextHieedn cur">{{ item.accountCount ||"无" }}</span>
          </div>
        </vue-seamless-scroll>
      </div>
    </div>
  </div>
</template>

<script>
import echartMixin from "@/mixins/echartMixin";
import { misreAccountLeaderboard } from "@/api/cwdaping/index";
export default {
  mixins: [echartMixin],
  created() {
    this.setInfo();
  },
  data() {
    return {
      list: [
        { name: '一月又一月', type: '算得上是所', num: 111 },
        { name: '一月又一月', type: '算得上是所', num: 111 },
        { name: '一月又一月', type: '算得上是所', num: 111 },
        { name: '一月又一月', type: '算得上是所', num: 111 },
        { name: '一月又一月', type: '算得上是所', num: 111 },
      ],
    };
  },
  methods: {
    getIndexColor(val) {
      let obj = {
        1: 'background-lv1',
        2: 'background-lv2',
        3: 'background-lv3',
      }
      return obj[val]
    },
    setInfo(period = 1) {
      this.getInfo({
        APiFn: misreAccountLeaderboard,
        data: { period },
      });
    },
    getEcharts(ResData) {
      console.log(ResData);
      this.list = ResData
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;
  padding: 0.1412rem 0.1882rem;
  overflow: hidden;
  .list {
    .list-title {
      width: 100%;
      height: 0.3765rem;
      background: #0f1e46;
      //   opacity: 0.08;
      margin-bottom: 0.2588rem;
      color: #d0e0ff;
      font-size: 0.1647rem;
      line-height: 0.3765rem;
      text-align: center;
      span {
        width: 20%;
      }
    }
    .list-box {
      overflow: hidden;
      .list-item {
        text-align: center;
        font-size: 0.1647rem;
        color: #bacef4;
        line-height: 0.3529rem;
        margin-bottom: 0.2588rem;
        span {
          width: 20%;
          display: inline-block;
        }
        .index {
          width: 0.2118rem;
          height: 0.2118rem;
          line-height: 0.2118rem;
          text-align: center;
          border: 0.0118rem solid #007aaf;
          color: #fff;
          border-radius: 0.0353rem;
          margin: 0 auto;
        }
        .background-lv1 {
          background: #e72f31 !important;
          border: none !important;
        }
        .background-lv2 {
          background: #e49b09 !important;
          border: none !important;
        }
        .background-lv3 {
          background: #008eb7 !important;
          border: none !important;
        }
      }
    }
  }
}
</style>
<style>
.atooltip {
  font-size: 0.1647rem !important;
  padding: 0.0941rem !important;
  line-height: 0.2353rem !important;
  border-radius: 0.0471rem !important;
  left: 2.3529rem !important;
}
.atooltip .popper__arrow {
  display: none;
}
/* .btooltip {
  font-size: 0.1647rem !important;
  padding: 0.0941rem !important;
  line-height: 0.2353rem !important;
  border-radius: 0.0471rem !important;
  left: 1.7647rem !important;
}
.btooltip .popper__arrow {
  display: none;
} */
</style>
